// src/components/NossosClientes.js
import React from 'react';
import { Typography } from '@mui/material';

const NossosClientes = () => {
    return (
        <div>
            <Typography variant="h5">
                Nossos Clientes
            </Typography>
            <Typography>
                Nossos Clientes incluem empresas de diversos setores que confiam em nossas soluções para transformar seus negócios. Trabalhamos com grandes nomes e startups emergentes, oferecendo serviços personalizados para cada um.
            </Typography>
        </div>
    );
};

export default NossosClientes;
