// src/components/QuemSomos.js
import React from 'react';
import { Typography } from '@mui/material';

const QuemSomos = () => {
    return (
        <div>
            <Typography variant="h5">
                Sobre a GenX
            </Typography>
            <Typography>
                Na GenX, nos dedicamos ao desenvolvimento de soluções inovadoras que ajudam empresas a resolver problemas complexos utilizando as tecnologias mais atuais e emergentes do mercado. Nosso objetivo é sempre criar valor, cortando custos e otimizando processos por meio da transformação digital e de uma abordagem disruptiva ao desenvolvimento de software.
            </Typography>
            <Typography>
                Transformação Digital é um dos principais pilares de nossa atuação. Ajudamos empresas a migrar de processos manuais e ineficientes para soluções digitais que aumentam a produtividade, melhoram a eficiência operacional e reduzem custos. Esse processo envolve modernizar infraestruturas, automatizar tarefas, implementar sistemas integrados e adaptar as organizações para a era digital. Isso não se trata apenas de adotar novas tecnologias, mas de transformar a maneira como os negócios funcionam, gerando novas oportunidades e proporcionando mais agilidade no mercado.
            </Typography>
            <Typography>
                Além disso, na GenX temos uma abordagem disruptiva no desenvolvimento de aplicações. Quando olhamos para um problema, fazemos isso de fora para dentro, como quem vê uma nova perspectiva. Isso nos permite identificar soluções inovadoras que muitas vezes não são visíveis para quem está imerso no dia a dia do desafio. Esse método está relacionado ao conceito de "pensamento disruptivo", onde saímos do tradicional para buscar formas novas e criativas de resolver problemas. O resultado são aplicações eficientes e direcionadas, que resolvem problemas reais com simplicidade e precisão.
            </Typography>
            <Typography>
                Seja para otimizar processos internos, atender novas demandas do mercado, ou simplesmente digitalizar uma operação, a GenX está sempre preparada para entender o contexto único de cada cliente e desenvolver a solução ideal. Trabalhamos com uma visão de futuro, sempre atentos às mudanças tecnológicas e de mercado, e adaptamos nossa abordagem para proporcionar resultados duradouros.
            </Typography>
        </div>
    );
};

export default QuemSomos;
