// src/App.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Grid } from '@mui/material';
import QuemSomos from './components/QuemSomos';
import NossosClientes from './components/NossosClientes';
import Contato from './components/Contato';

function App() {
  // Estado para definir qual conteúdo será exibido do lado direito
  const [content, setContent] = useState('Quem Somos');

  // Função para renderizar o conteúdo com base no botão clicado
  const renderContent = () => {
    switch (content) {
      case 'Quem Somos':
        return <QuemSomos />;
      case 'Nossos Clientes':
        return <NossosClientes />;
      case 'Contato':
        return <Contato />;
      default:
        return null;
    }
  };

  return (
    <div>
      {/* Barra de Navegação */}
      <AppBar position="static" sx={{ backgroundColor: '#1A1A40' }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            GenX
          </Typography>
          <Button color="inherit" onClick={() => setContent('Quem Somos')}>Quem Somos</Button>
          <Button color="inherit" onClick={() => setContent('Nossos Clientes')}>Nossos Clientes</Button>
          <Button color="inherit" onClick={() => setContent('Contato')}>Contato</Button>
        </Toolbar>
      </AppBar>

      {/* Divisão da página em duas partes */}
      <Grid container style={{ height: '100vh' }}>
        {/* Lado esquerdo: Logo */}
        <Grid item xs={12} md={6} style={{ backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <img src="/img/logoGenxAzul.png" alt="Logo GenX" style={{ maxWidth: '200px', height: 'auto' }} />
        </Grid>

        {/* Lado direito: Conteúdo dinâmico com scroll */}
        <Grid item xs={12} md={6} style={{ backgroundColor: 'white', height: '100vh', overflowY: 'auto', padding: '20px' }}>
          {renderContent()}
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
