// src/components/Contato.js
import React from 'react';
import { Typography, Box, Button, TextField } from '@mui/material';

const Contato = () => {
  return (
    <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Typography variant="h5">Contato</Typography>
      <TextField label="Nome" variant="outlined" required />
      <TextField label="Email" type="email" variant="outlined" required />
      <TextField label="Telefone" type="tel" variant="outlined" required />
      <TextField label="Mensagem" multiline rows={4} variant="outlined" required />
      <Button variant="contained" type="submit">Enviar</Button>
    </Box>
  );
};

export default Contato;
